import React from 'react';
import { Link } from 'gatsby';

import logo from '../../assets/images/logo-white.svg';

import './styles.scss';

const PageNotFound = (props) => (
  <div className='page-not-found'>
    <img className='logo' src={logo} alt='CrustByte' title='CrustByte' />
    <h1>Sorry, couldn't find what you are looking for <span role="img" aria-label="sad">😢</span></h1>
    <h3>
      <Link to="/"><span role="img" aria-label="back">👈</span> &nbsp; Back to Homepage</Link>
    </h3>
  </div>
);

export default PageNotFound;
